<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 312 142.8" class="h-14 w-auto">
        <path fill="#FFFFFF" d="M0.8,47.6c15.7,0.9,26.3,1.3,31.8,1.3c1.3,0,2.4-0.4,3.1-1.2s1.2-1.8,1.2-3.1v-7.7H17.2
            c-6,0-10.4-1.4-13.1-4.1S0,25.8,0,19.7v-2.6C0,11.1,1.4,6.7,4.1,4c2.7-2.6,7.1-4,13.1-4h30.1v12H20.6c-3.4,0-5.2,1.7-5.2,5.2v1.7
            c0,3.4,1.7,5.2,5.2,5.2h15.5c5.6,0,9.7,1.3,12.4,3.9c2.6,2.6,3.9,6.8,3.9,12.4v4.3c0,5.6-1.3,9.7-3.9,12.4
            c-2.6,2.6-6.8,3.9-12.4,3.9c-2.9,0-6-0.1-9.3-0.2l-7.9-0.3C13,60.1,7,59.7,0.8,59.2V47.6z"/>
        <path fill="#FFFFFF" d="M74.3,60.1v14.6h-15V14.6h13.3l0.9,4.3c2.7-1.8,5.5-3.1,8.3-3.9c2.8-0.8,5.2-1.2,7.2-1.2h3.9
            c3.9,0,7,1.2,9.4,3.5c2.3,2.3,3.5,5.5,3.5,9.4v19.7c0,4.3-1.2,7.7-3.6,10.1c-2.4,2.4-5.8,3.6-10.1,3.6H74.3z M84.6,26.2
            c-3,0-6.5,0.6-10.3,1.7v20.6h12.9c2.3,0,3.4-1.1,3.4-3.4V29.2c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.6-1.3-0.9-2.1-0.9H84.6z"/>
        <path fill="#FFFFFF" d="M155.4,59.2c-11,1.1-21.1,1.7-30,1.7c-3.9,0-7-1.2-9.4-3.5c-2.3-2.3-3.5-5.5-3.5-9.4V27.5
            c0-4.3,1.2-7.7,3.6-10.1s5.8-3.6,10.1-3.6h17.2c4.3,0,7.7,1.2,10.1,3.6c2.4,2.4,3.6,5.8,3.6,10.1v15.9h-29.6v3.4
            c0,0.9,0.3,1.6,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9c5.7,0,14-0.4,24.9-1.3L155.4,59.2L155.4,59.2z M130.9,24.5c-2.3,0-3.4,1.1-3.4,3.4
            v4.7h14.6v-4.7c0-2.3-1.1-3.4-3.4-3.4C138.7,24.5,130.9,24.5,130.9,24.5z"/>
        <path fill="#FFFFFF" d="M206.9,59.2c-11,1.1-21.1,1.7-30,1.7c-3.9,0-7-1.2-9.4-3.5c-2.3-2.3-3.5-5.5-3.5-9.4V27.5
            c0-4.3,1.2-7.7,3.6-10.1c2.4-2.4,5.8-3.6,10.1-3.6h17.2c4.3,0,7.7,1.2,10.1,3.6c2.4,2.4,3.6,5.8,3.6,10.1v15.9H179v3.4
            c0,0.9,0.3,1.6,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9c5.7,0,14-0.4,24.9-1.3V59.2z M182.4,24.5c-2.3,0-3.4,1.1-3.4,3.4v4.7h14.6v-4.7
            c0-2.3-1.1-3.4-3.4-3.4C190.2,24.5,182.4,24.5,182.4,24.5z"/>
        <path fill="#FFFFFF" d="M248.5,60.1l-0.9-4.3c-2.7,1.8-5.5,3.1-8.3,3.9c-2.8,0.8-5.2,1.2-7.2,1.2h-3.9c-3.9,0-7-1.2-9.4-3.5
            c-2.3-2.3-3.5-5.5-3.5-9.4V28.3c0-4.3,1.2-7.7,3.6-10.1c2.4-2.4,5.8-3.6,10.1-3.6h17.6V0h15v60.1H248.5z M236.5,48.5
            c3,0,6.5-0.6,10.3-1.7V26.2h-12.9c-2.3,0-3.4,1.1-3.4,3.4v15.9c0,0.9,0.3,1.6,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9H236.5z"/>
        <path fill="#FFFFFF" d="M15.5,115.2h31.3v12H0v-60h15.5V115.2z"/>
        <path fill="#FFFFFF" d="M98.3,114.4c0,4.3-1.2,7.7-3.6,10.1s-5.8,3.6-10.1,3.6H64.8c-4.3,0-7.7-1.2-10.1-3.6
            c-2.4-2.4-3.6-5.8-3.6-10.1V94.6c0-4.3,1.2-7.7,3.6-10.1c2.4-2.4,5.8-3.6,10.1-3.6h19.7c4.3,0,7.7,1.2,10.1,3.6s3.6,5.8,3.6,10.1
            v19.8H98.3z M83.3,95.9c0-2.3-1.1-3.4-3.4-3.4H69.5c-2.3,0-3.4,1.1-3.4,3.4v17.2c0,2.3,1.1,3.4,3.4,3.4h10.3c2.3,0,3.4-1.1,3.4-3.4
            L83.3,95.9L83.3,95.9z"/>
        <path fill="#FFFFFF" d="M127,115.7c3,0,6.5-0.6,10.3-1.7V81.8h15v45.5H139l-0.9-4.3c-2.7,1.8-5.5,3.1-8.3,3.9s-5.2,1.2-7.2,1.2h-3.9
            c-3.9,0-7-1.2-9.4-3.5c-2.3-2.3-3.5-5.5-3.5-9.4V81.8h15v30.9c0,0.9,0.3,1.6,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9
            C123.8,115.7,127,115.7,127,115.7z"/>
        <path fill="#FFFFFF" d="M186.3,93.3c-3,0-6.5,0.6-10.3,1.7v32.2h-15V81.8h13.3l0.9,4.3c2.7-1.8,5.5-3.1,8.3-3.9s5.2-1.2,7.2-1.2h3.9
            c3.9,0,7,1.2,9.4,3.5c2.3,2.3,3.5,5.5,3.5,9.4v33.5h-15V96.3c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.6-1.3-0.9-2.1-0.9H186.3z"/>
        <path fill="#FFFFFF" d="M244.7,123.8c-5.6,1.1-10.4,1.7-14.6,1.7h-3c-3.9,0-7-1.2-9.4-3.5c-2.3-2.3-3.5-5.5-3.5-9.4V95.5
            c0-4.3,1.2-7.7,3.6-10.1c2.4-2.4,5.8-3.6,10.1-3.6h31.8v48.1c0,3.9-1.2,7-3.5,9.4c-2.3,2.3-5.5,3.5-9.4,3.5c-9,0-19-0.6-30-1.7
            v-11.2c10.9,0.9,19.2,1.3,24.9,1.3c0.9,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1L244.7,123.8L244.7,123.8z M234.3,113.9
            c3.6,0,7-0.3,10.3-0.9V93.3h-12c-2.3,0-3.4,1.1-3.4,3.4v14.2c0,0.9,0.3,1.6,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9H234.3z"/>
        <path fill="#FFFFFF" d="M310.3,126.4c-11,1.1-21.1,1.7-30,1.7c-3.9,0-7-1.2-9.4-3.5c-2.3-2.3-3.5-5.5-3.5-9.4V94.6
            c0-4.3,1.2-7.7,3.6-10.1s5.8-3.6,10.1-3.6h17.2c4.3,0,7.7,1.2,10.1,3.6s3.6,5.8,3.6,10.1v15.9h-29.6v3.4c0,0.9,0.3,1.6,0.9,2.1
            c0.6,0.6,1.3,0.9,2.1,0.9c5.7,0,14-0.4,24.9-1.3L310.3,126.4L310.3,126.4z M285.9,91.6c-2.3,0-3.4,1.1-3.4,3.4v4.7H297V95
            c0-2.3-1.1-3.4-3.4-3.4H285.9z"/>
    </svg>
</template>
